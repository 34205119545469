import * as React from "react"
import Layout from "../components/layout"
import { useFormik } from "formik"
import {
  addUtmTagsInUrls,
  getUtmFromCookie,
  setCookieFromUrl,
} from "../widgets/cookie"
import { navigate } from "gatsby"

//contact up page which asks for first_name, last_name,phone_number, company, email, notes and utm tags from cookie as landing_url_params (Ex. {utm_campaign:'sandesh', utm_source:'instagram'})
//validation using formik form
//validation on first_name is minimum 3 words and maximum 64
//validation on last_name is minimum 3 words and maximum 64
//validation on phone_number is valid Indian phone number
//validation on company is minimum 3 words and maximum 64
//validation on email is valid email
//validation on notes is minimum 3 words and maximum 500
//validation on landing_url_params is valid javascript object (Ex. {utm_campaign:'sandesh', utm_source:'instagram'})
//if any validation fails then show error message
//on submit call api https://hybrid.opareviews.com/c/contact-us with body {first_name, last_name,phone_number, company, email, notes, landing_url_params}
//if api call fails then show error notification "Something went wrong"
//if api call succeeds then show success component and give option to go to home page and fill form again
export default function ContactUs(props: any) {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      company: "",
      email: "",
      notes: "",
      landing_url_params: getUtmFromCookie(),
      employee_range: "",
      industry: "",
    },
    onSubmit: (values) => {
      setLoading(true)
      fetch("https://hybrid.opareviews.com/c/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          phone_number: "+91" + values.phone_number,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          //push to thank you page
          navigate("/thank-you")
          if (res.message === "OK") {
            setSuccess(true)
            setLoading(false)
          } else {
            setError(true)
            setLoading(false)
          }
        })
        .catch((err) => {
          setError(true)
          setLoading(false)
        })
    },
    validate: (values) => {
      //validate field only if it is touched by user
      // validate all form  fields  if submit button is clicked
      //trim values before validating

      const errors: any = {}
      if (
        (values.first_name.trim().length < 3 ||
          values.first_name.trim().length > 64) &&
        (formik.touched.first_name || formik.isSubmitting)
      ) {
        errors.first_name = "First name should be between 3-64 characters"
      }
      if (
        (values.last_name.trim().length < 3 ||
          values.last_name.trim().length > 64) &&
        (formik.touched.last_name || formik.isSubmitting)
      ) {
        errors.last_name = "Last name should be between 3-64 characters"
      }
      if (
        !values.phone_number.trim().match(/^\d{10}$/) &&
        (formik.touched.phone_number || formik.isSubmitting)
      ) {
        errors.phone_number = "Invalid phone number"
      }
      if (
        (values.company.trim().length < 3 ||
          values.company.trim().length > 64) &&
        (formik.touched.company || formik.isSubmitting)
      ) {
        errors.company = "Company name should be between 3-64 characters"
      }
      if (
        !values.email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
        (formik.touched.email || formik.isSubmitting)
      ) {
        errors.email = "Invalid email"
      }
      if (
        ((values.notes.trim().length < 3 && values.notes.trim().length !== 0) ||
          values.notes.trim().length > 500) &&
        (formik.touched.notes || formik.isSubmitting)
      ) {
        errors.notes = "Notes should be between 3-500 characters"
      }
      if (
        values.employee_range === "" &&
        (formik.touched.employee_range || formik.isSubmitting)
      ) {
        errors.employee_range = "Please select number of employees"
      }
      if (
        values.industry === "" &&
        (formik.touched.industry || formik.isSubmitting)
      ) {
        errors.industry = "Please select industry"
      }

      return errors
    },
  })

  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setCookieFromUrl()
    // initializeAndTrack(location)
  }, [1])
  React.useEffect(() => {
    console.log(document.querySelectorAll("a"))
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])

  return (
    <Layout {...props}>
      <div className="container">
        <div className="contactUsContainer">
          {success ? (
            <div className="success">
              <h1>Thank you for contacting us</h1>
              <p>
                We will get back to you soon. In the mean time you can go to
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <a
                    href="/"
                    className="link-style-button"
                    onClick={() => {
                      setSuccess(false)
                      setError(false)
                      formik.resetForm()
                    }}
                  >
                    home page
                  </a>
                </div>
              </p>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} className="contactUsContent">
              <div className="form-group">
                <h1 style={{ textAlign: "center" }}>Contact Us</h1>

                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
                {formik.errors.first_name && (
                  <div className="error">{formik.errors.first_name}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
                {formik.errors.last_name && (
                  <div className="error">{formik.errors.last_name}</div>
                )}
              </div>
              {/* phone number input field with +91 prefix  */}
              <div className="form-group">
                <label htmlFor="phone_number">Phone Number</label>
                <div className="input-prefix-item">
                  <span className="input-prefix-item-phone-number-icon">
                    +91
                  </span>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    className="input-prefix-item-input"
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                  />
                </div>
                {formik.errors.phone_number && (
                  <div className="error">{formik.errors.phone_number}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  onChange={formik.handleChange}
                  value={formik.values.company}
                />
                {formik.errors.company && (
                  <div className="error">{formik.errors.company}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>
              <div className="rowItem">
                <div className="form-group-1">
                  <label htmlFor="employee_range">No. of Employees</label>
                  <select
                    name="employee_range"
                    id="employee_range"
                    onChange={formik.handleChange}
                    value={formik.values.employee_range}
                  >
                    <option value="" selected>
                      -- select an option --
                    </option>
                    <option value="1-50"> 1-50</option>
                    <option value="50-200">50-200</option>
                    <option value="200+">200+</option>
                  </select>
                  {formik.errors.employee_range && (
                    <div className="error">{formik.errors.employee_range}</div>
                  )}
                </div>

                <div className="form-group-1">
                  <label htmlFor="industry">Industry</label>
                  <select
                    name="industry"
                    id="industry"
                    onChange={formik.handleChange}
                    value={formik.values.industry}
                  >
                    <option selected value="">
                      -- select an option --
                    </option>
                    {/* [ FMCG, Cosmetics, Skin Care, Hair Care, Fashion, Accessories, Fitness, Health & Wellness, Food, Hygiene, Kids & Home, Electronics, Apps, Others ] */}
                    <option value="FMCG">FMCG</option>
                    <option value="Cosmetics">Cosmetics</option>
                    <option value="Skin Care">Skin Care</option>
                    <option value="Hair Care">Hair Care</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Accessories">Accessories</option>
                    <option value="Fitness">Fitness</option>
                    <option value="Health & Wellness">Health & Wellness</option>
                    <option value="Food">Food</option>
                    <option value="Hygiene">Hygiene</option>
                    <option value="Kids & Home">Kids & Home</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Apps">Apps</option>
                    <option value="Others">Others</option>
                  </select>
                  {formik.errors.industry && (
                    <div className="error">{formik.errors.industry}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea
                  name="notes"
                  id="notes"
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                />
                {formik.errors.notes && (
                  <div className="error">{formik.errors.notes}</div>
                )}
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className={`contactUsBtn ${
                    loading ? "contact-disabled" : ""
                  }`}
                  onClick={() => {
                    formik.setSubmitting(true)
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {loading && <div className="loading-circular"></div>}
                    Submit
                  </div>
                </button>
                {error && (
                  <div className="error">Something went wrong, Try again.</div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </Layout>
  )
}
